exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-category-tsx": () => import("./../../../src/pages/category.tsx" /* webpackChunkName: "component---src-pages-category-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-publications-tsx": () => import("./../../../src/pages/publications.tsx" /* webpackChunkName: "component---src-pages-publications-tsx" */),
  "component---src-templates-post-tsx-content-file-path-blog-black-lives-matter-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/blog/black-lives-matter/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-blog-black-lives-matter-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-blog-can-the-club-machine-ever-be-authentic-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/blog/can-the-club-machine-ever-be-authentic/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-blog-can-the-club-machine-ever-be-authentic-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-blog-italian-prose-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/blog/italian-prose/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-blog-italian-prose-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-blog-maradona-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/blog/maradona/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-blog-maradona-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-blog-music-manifesto-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/blog/music-manifesto/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-blog-music-manifesto-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-blog-sneaker-heads-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/blog/sneaker-heads/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-blog-sneaker-heads-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-blog-tintagel-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/blog/tintagel/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-blog-tintagel-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-blog-titi-finlay-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/blog/titi-finlay/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-blog-titi-finlay-index-mdx" */)
}

